import classNames from 'classnames';
import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import {
  Position,
  So5LeaderboardSeasonality,
} from '@sorare/core/src/__generated__/globalTypes';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelS } from '@sorare/core/src/atoms/typography';
import { PositionIcon } from '@sorare/core/src/components/lineup/PositionIcon';

const Count = styled(Horizontal).attrs({ gap: 0.5 })`
  padding: var(--half-unit);
  color: var(--c-nd-600);

  &.hasCards {
    color: var(--c-white);
    background: rgba(var(--c-rgb-white), 0.1);
    border-radius: var(--half-unit);
  }

  & > ${LabelS} {
    font-variant-numeric: tabular-nums;
  }

  &.IN_SEASON {
    ${LabelS} {
      background: linear-gradient(
        84deg,
        #f8d3da 0%,
        #b3a9f4 28.32%,
        #fbe9fb 54.01%,
        #4f94fd 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:not(.hasCards) {
      opacity: 0.5;
    }
  }
`;

type Props = {
  position: Position;
  count: number;
  seasonality?: So5LeaderboardSeasonality;
};

export const PositionCardCount = ({ position, count, seasonality }: Props) => {
  return (
    <Count className={classNames(seasonality, { hasCards: !!count })}>
      <PositionIcon
        position={position}
        hollow={seasonality === So5LeaderboardSeasonality.IN_SEASON}
        size={1.5}
      />
      <LabelS as="p" bold brand>
        <FormattedNumber value={count} />
      </LabelS>
    </Count>
  );
};
