import { ReactNode } from 'react';
import { To } from 'react-router-dom';
import styled from 'styled-components';

import { Link } from '@sorare/routing';

import { Vertical } from 'atoms/layout/flex';

const Root = styled(Link)`
  display: flex;
  align-items: center;
  gap: var(--double-unit);

  background-color: var(--c-nd-50);
  border-radius: var(--intermediate-unit);
  padding: var(--triple-unit);
  width: 340px;

  &:hover {
    background-color: var(--c-nd-100);
  }
  transition: background-color 100ms ease-in-out;
`;
const IconWrapper = styled.div`
  width: min-content;
`;
const FullWidthVertical = styled(Vertical).attrs({ gap: 0 })`
  flex: 1;
`;

type Props = {
  to: To;
  icon: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  onClick?: () => void;
};
export const SportLink = ({ to, icon, title, subtitle, onClick }: Props) => {
  return (
    <Root to={to} onClick={onClick}>
      <IconWrapper>{icon}</IconWrapper>
      <FullWidthVertical>
        {title}
        {subtitle}
      </FullWidthVertical>
    </Root>
  );
};
