import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { TextInput } from 'atoms/inputs/TextInput';

import { SearchBarDialog } from './SearchBarDialog';

const StyledTextInput = styled(TextInput)`
  background: rgba(var(--c-rgb-white), 0.04);
  border: 1px solid var(--c-nd-50);

  &,
  & * {
    cursor: pointer;
  }

  &:hover {
    border-color: var(--c-nd-150);
  }
  transition: 300ms border-color ease-in-out;
`;

const placeholder = defineMessage({
  id: 'SearchBar.placeholder',
  defaultMessage: 'Search...',
});

export const SearchBar = () => {
  const { formatMessage } = useIntl();
  const [searchDialogOpened, setSearchDialogOpened] = useState(false);
  return (
    <>
      <button onClick={() => setSearchDialogOpened(true)} type="button">
        <StyledTextInput
          type="search"
          startAdornment={
            <FontAwesomeIcon icon={faSearch} color="var(--c-nd-600)" />
          }
          placeholder={formatMessage(placeholder)}
        />
      </button>
      {searchDialogOpened && (
        <SearchBarDialog onClose={() => setSearchDialogOpened(false)} />
      )}
    </>
  );
};
