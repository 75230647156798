import { type TypedDocumentNode, gql } from '@apollo/client';
import classNames from 'classnames';
import { CSSProperties, ComponentProps } from 'react';
import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelS, LabelXS } from '@sorare/core/src/atoms/typography';
import { Color, RGBColor } from '@sorare/core/src/style/types';

import { BonusBreakdownTooltip } from './BonusBreakdownTooltip';
import type { BonusChip_powerBreakdown } from './__generated__/index.graphql';

const Root = styled(Horizontal).attrs({ center: true })`
  min-width: var(--quadruple-unit);
  min-height: var(--chip-small-height);
  display: flex;
  gap: 1px;
  border-radius: 9999px;
  border: 1px solid var(--c-nd-400);
  background: linear-gradient(-30deg, var(--c-nd-200) 50%, var(--c-nd-100) 50%);
  padding: 0 2px;

  &.colored {
    --color: rgba(var(--rgb-color), 0.1);
    border-color: rgb(var(--rgb-color));
    background: linear-gradient(var(--color), var(--color)),
      linear-gradient(-30deg, var(--c-nd-200) 50%, var(--c-nd-100) 50%);
  }
`;

type Props = {
  bonus: number;
  powerBreakdown?: BonusChip_powerBreakdown | null;
  captain?: boolean;
  captainBonus?: number | null;
  size?: 'small' | 'large';
  rgbColor?: RGBColor;
  withTransferMalus?: boolean;
} & Pick<ComponentProps<typeof BonusBreakdownTooltip>, 'transferMalus'>;

export const BonusChip = ({
  bonus,
  powerBreakdown,
  captain,
  captainBonus,
  withTransferMalus,
  transferMalus,
  size = 'small',
  rgbColor,
}: Props) => {
  const hasCaptainBonusAlready =
    (powerBreakdown?.captainBasisPoints || 0) !== 0;

  let actualBonus = bonus - 1;
  if (captain && !hasCaptainBonusAlready) {
    actualBonus += captainBonus || 0;
  }
  if (!captain && hasCaptainBonusAlready) {
    actualBonus -= captainBonus || 0;
  }
  if (withTransferMalus) {
    if (transferMalus) actualBonus += transferMalus;
    if (powerBreakdown?.collectionBasisPoints) {
      actualBonus -= powerBreakdown.collectionBasisPoints / 10000;
    }
  }

  const Label = size === 'small' ? LabelXS : LabelS;

  const color: Color | undefined = rgbColor
    ? (`rgb(${rgbColor})` as Color)
    : undefined;
  const chip = (
    <Root
      style={{ '--rgb-color': rgbColor } as CSSProperties}
      className={classNames(size, { colored: !!color })}
    >
      <Label bold color={color} brand>
        <FormattedNumber value={actualBonus * 100} signDisplay="always" />
      </Label>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="7"
        viewBox="0 0 7 7"
        fill="none"
      >
        <path
          d="M3.04546 1.77273C3.04546 1.07143 2.47403 0.5 1.77273 0.5C1.07143 0.5 0.5 1.07143 0.5 1.77273C0.5 2.47403 1.07143 3.04546 1.77273 3.04546C2.47403 3.04546 3.04546 2.47403 3.04546 1.77273ZM0.88961 6.42208C0.98052 6.42208 1.08442 6.38312 1.16234 6.3052L6.29221 1.17532C6.42208 1.03247 6.42208 0.785714 6.27922 0.642857C6.13637 0.5 5.88961 0.5 5.74676 0.642857L0.616883 5.77273C0.474026 5.91559 0.474026 6.16234 0.616883 6.3052C0.694805 6.38312 0.785714 6.42208 0.88961 6.42208ZM3.95455 5.22727C3.95455 5.92857 4.52598 6.5 5.22727 6.5C5.92857 6.5 6.5 5.92857 6.5 5.22727C6.5 4.52598 5.92857 3.95455 5.22727 3.95455C4.52598 3.95455 3.95455 4.52598 3.95455 5.22727Z"
          fill={color || 'white'}
        />
      </svg>
    </Root>
  );

  if (powerBreakdown) {
    const displayPowerBreakdown = {
      ...powerBreakdown,
      ...(withTransferMalus
        ? { collectionBasisPoints: 0 }
        : { collectionBasisPoints: powerBreakdown.collectionBasisPoints }),
    };

    return (
      <BonusBreakdownTooltip
        powerBreakdown={displayPowerBreakdown}
        captainBonus={captain ? captainBonus : 0}
        transferMalus={transferMalus}
      >
        {chip}
      </BonusBreakdownTooltip>
    );
  }

  return chip;
};

BonusChip.fragments = {
  powerBreakdown: gql`
    fragment BonusChip_powerBreakdown on PowerBreakdown {
      captainBasisPoints
      collectionBasisPoints
      ...BonusBreakdownTooltip_powerBreakdown
    }
    ${BonusBreakdownTooltip.fragments.powerBreakdown}
  ` as TypedDocumentNode<BonusChip_powerBreakdown>,
};
