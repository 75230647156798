import { TypedDocumentNode, gql } from '@apollo/client';

import {
  useMonetaryAmount,
  zeroMonetaryAmount,
} from '@sorare/core/src/hooks/useMonetaryAmount';
import { useEvents } from '@sorare/core/src/lib/events/useEvents';
import { monetaryAmountFragment } from '@sorare/core/src/lib/monetaryAmount';
import { fromWei } from '@sorare/core/src/lib/wei';
import {
  ClickAddToCart_Origin,
  ClickRemoveFromCart_Origin,
  ClickSaveForLater_Origin,
} from '@sorare/core/src/protos/events/so5/shared/events';

import { useCart } from 'hooks/useCart';

import { useCartEvents_anyCard } from './__generated__/useCartEvents.graphql';

type Props = {
  anyCard: Nullable<useCartEvents_anyCard>;
  composeSessionId?: string;
};

export const useCartEvents = ({ anyCard, composeSessionId = '' }: Props) => {
  const { toMonetaryAmount } = useMonetaryAmount();

  const { cart } = useCart();
  const track = useEvents();

  const price =
    (anyCard?.latestPrimaryOffer?.price &&
      toMonetaryAmount(anyCard.latestPrimaryOffer.price)) ||
    zeroMonetaryAmount;
  const properties = {
    cartId: cart?.id || '',
    offerId: anyCard?.latestPrimaryOffer?.id || '',
    cardSlug: anyCard?.slug || '',
    ethAmount: price && fromWei(price.wei),
    eurAmount: price && price.eur / 100,
    scarcity: anyCard?.rarityTyped || '',
    composeSessionId,
  };
  const trackClickAddToCart = (origin: ClickAddToCart_Origin) => {
    track('Click Add To Cart', {
      ...properties,
      origin,
    });
  };

  const trackClickSaveForLater = (origin: ClickSaveForLater_Origin) => {
    track('Click Save For Later', {
      ...properties,
      origin,
    });
  };

  const trackClickRemoveFromCart = (origin: ClickRemoveFromCart_Origin) => {
    track('Click Remove From Cart', {
      ...properties,
      origin,
    });
  };

  return {
    trackClickAddToCart,
    trackClickSaveForLater,
    trackClickRemoveFromCart,
  };
};

useCartEvents.fragments = {
  anyCard: gql`
    fragment useCartEvents_anyCard on AnyCardInterface {
      slug
      sport
      rarityTyped
      latestPrimaryOffer {
        id
        price {
          ...MonetaryAmountFragment_monetaryAmount
        }
      }
    }
    ${monetaryAmountFragment}
  ` as TypedDocumentNode<useCartEvents_anyCard>,
};
