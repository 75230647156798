import { Fragment } from 'react';
import styled from 'styled-components';

import { Vertical } from 'atoms/layout/flex';
import { Dialog } from 'components/dialog';
import { useSportContext } from 'contexts/sport';

import { CurrencyData } from '../useUserCurrencies';

const Root = styled(Vertical)`
  padding: var(--double-unit);
`;

type Props = {
  open?: boolean;
  onClose?: () => void;
  currencies: CurrencyData[];
};
export const MobileUserCurrenciesDialog = ({
  open,
  onClose,
  currencies,
}: Props) => {
  const { sport } = useSportContext();
  return (
    <Dialog open={open} onClose={onClose}>
      <Root>
        {currencies.map(
          ({ id, details, visibleOnSports }) =>
            visibleOnSports.includes(sport) && (
              <Fragment key={id}>{details?.({ onClick: onClose })}</Fragment>
            )
        )}
      </Root>
    </Dialog>
  );
};
