import { Currency, SupportedCurrency } from '__generated__/globalTypes';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useAmountWithConversion } from 'hooks/useAmountWithConversion';
import { ETH_DECIMAL_PLACES, RoundingMode, fromWei, toWei } from 'lib/wei';

export const useEthBalance = () => {
  const { currentUser, currency } = useCurrentUserContext();

  const roundedWeiValue = toWei(
    fromWei(
      currentUser?.availableBalance || 0n,
      ETH_DECIMAL_PLACES,
      RoundingMode.ROUND_DOWN
    )
  );

  const { main, exponent } = useAmountWithConversion({
    monetaryAmount: {
      referenceCurrency: SupportedCurrency.WEI,
      wei: roundedWeiValue,
    },
  });
  const hideBalance = currentUser?.userSettings?.hideBalance;

  return {
    hideBalance,
    balanceInFiat: currency === Currency.ETH ? exponent : main,
    balanceInEth: currency === Currency.ETH ? main : exponent,
  };
};
