import classNames from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components';

import { ButtonBase } from '@sorare/core/src/atoms/buttons/ButtonBase';
import { ConditionalWrapper } from '@sorare/core/src/atoms/layout/ConditionalWrapper';
import { Vertical } from '@sorare/core/src/atoms/layout/flex';

const Root = styled(Vertical).attrs({ gap: 1 })`
  position: relative;
  isolation: isolate;
`;
const Card = styled.div`
  position: relative;
  isolation: isolate;
  aspect-ratio: var(--card-aspect-ratio);
  transition: filter 0.3s ease-in-out;
  &:hover {
    filter: brightness(120%);
  }

  &.selected {
    --overlay-color: var(--c-brand-600);
  }
  &.dropped {
    --overlay-color: var(--c-red-600);
  }
  &.selected,
  &.dropped {
    outline: 2px solid var(--overlay-color);

    border-radius: 6px;

    &::after {
      position: absolute;
      content: '';
      inset: 0;
      background: radial-gradient(
        154.46% 96.82% at 50% 3.18%,
        transparent 70%,
        var(--overlay-color) 100%
      );
      z-index: 1;
    }
  }
`;
const CornerBadge = styled.div`
  position: absolute;
  right: 0;
  top: var(--unit);

  &.selected {
    right: -2px;
  }
`;

type Props = {
  children: ReactNode;
  footer: ReactNode;
  cornerBadge?: ReactNode;
  dropped?: boolean;
  selected?: boolean;
  onClickCard?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  ariaLabel?: string;
  className?: string;
};

export const BasicBenchCard = ({
  children,
  footer,
  cornerBadge,
  dropped,
  selected,
  onClickCard,
  ariaLabel,
  className,
}: Props) => {
  return (
    <Root className={className}>
      <ConditionalWrapper
        Wrapper={ButtonBase}
        wrap={!!onClickCard}
        props={{ onClick: onClickCard }}
      >
        <Card
          as={!onClickCard ? 'div' : undefined}
          aria-label={ariaLabel}
          className={classNames({ selected, dropped })}
        >
          {children}
        </Card>
      </ConditionalWrapper>
      {footer}
      {cornerBadge && (
        <CornerBadge className={classNames({ selected })}>
          {cornerBadge}
        </CornerBadge>
      )}
    </Root>
  );
};
