import classNames from 'classnames';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import {
  BackFromSignUpAdditionalScreen,
  SetMode,
  SignUpAdditionalScreen,
} from '@sorare/wallet-shared';
import { Sport } from '__generated__/globalTypes';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { LabelL } from 'atoms/typography';
import { getValue } from 'components/PersistsQueryStringParameters/storage';
import { Dialog } from 'components/dialog';
import { ChooseYourSport } from 'components/landing/LandingMultiSport/ChooseYourSport';
import { OAuthForm } from 'components/user/OAuthForm';
import { useDeviceFingerprintContext } from 'contexts/deviceFingerprint';
import { useMessagingContext, useWalletContext } from 'contexts/wallet';
import { Placeholder as WalletPlaceholder } from 'contexts/wallet/Placeholder';
import { useScreenSize } from 'hooks/device/useScreenSize';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useIsMobileApp } from 'hooks/useIsMobileApp';
import { useEvents } from 'lib/events/useEvents';
import { glossary } from 'lib/glossary';
import { mustAcceptTermsOfServiceFlag } from 'lib/mustAcceptTermsOfServiceFlag';
import * as events from 'protos/events/so5/web/events';
import { SignUpWithOAuth_Sport } from 'protos/webview/channel/native_messages';
import { laptopAndAbove } from 'style/mediaQuery';

import { PopMode } from '..';

const modes = ['signin', 'signup'] as const;
type Mode = (typeof modes)[number];

export const isConnectionDialogMode = (action: string | null): action is Mode =>
  Boolean(action) && modes.includes(action as Mode);

const SPORTS: Record<Sport, SignUpWithOAuth_Sport> = {
  [Sport.FOOTBALL]: SignUpWithOAuth_Sport.FOOTBALL,
  [Sport.BASEBALL]: SignUpWithOAuth_Sport.BASEBALL,
  [Sport.NBA]: SignUpWithOAuth_Sport.NBA,
};

interface Props {
  open: boolean;
  isMobileWebviewSignUp: boolean;
  mode: Mode;
  onClose: () => void;
  setMode: (mode: Mode) => void;
  popMode: PopMode | null;
  defaultSport?: Sport;
}

const ConnectionOAuthToolbarV2 = ({ mode }: { mode: Mode }) => {
  const googleOAuth2Ref = useRef<HTMLFormElement>(null);
  const facebookOauth2Ref = useRef<HTMLFormElement>(null);
  const appleOauth2Ref = useRef<HTMLFormElement>(null);
  const track = useEvents();
  const { registerOAuthHandler } = useWalletContext();
  const { isAndroidApp, postMessage, nativeOAuthSignup } = useIsMobileApp();
  const { deviceFingerprint: getDeviceFingerPrint } =
    useDeviceFingerprintContext();

  useEffect(() => {
    const refsPerPlatform = {
      facebook: facebookOauth2Ref,
      google: googleOAuth2Ref,
      apple: appleOauth2Ref,
    };
    return registerOAuthHandler(
      async (
        platform,
        {
          nickname,
          sport,
          acceptTerms,
          acceptAgeLimit,
          acceptToShareAll,
          acceptToShareSpecific,
        }
      ) => {
        if (mode === 'signup') {
          track('Click Oauth Sign Up', {
            method: events.clickOauthSignUp_MethodFromJSON(
              platform.toUpperCase()
            ),
          });
        }

        if (isAndroidApp && nativeOAuthSignup) {
          postMessage('signUpWithOauth', {
            platform,
            nickname,
            impactClickId: getValue('irclickid') || undefined,
            googleClickId: getValue('gclid') || undefined,
            facebookClickId: getValue('fbclid') || undefined,
            twitterClickId: getValue('twclid') || undefined,
            tikTokClickId: getValue('ttclid') || undefined,
            redditClickId: getValue('rdt_cid') || undefined,
            sport: SPORTS[sport || Sport.FOOTBALL],
            acceptTerms: Boolean(acceptTerms),
            acceptGameRules: Boolean(acceptTerms),
            acceptAgeLimit: Boolean(acceptAgeLimit),
            acceptPrivacyPolicy: Boolean(acceptTerms),
            agreedToReceiveOffersFromPartners: Boolean(acceptToShareAll),
            agreedToReceiveOffersFromPartnersSpecific:
              acceptToShareSpecific || [],
          });
        } else {
          const formElement = refsPerPlatform[platform].current;
          if (formElement) {
            // This is a hack to forward the sport/terms/age/privacy policy acceptance & device fingerprint to the OAuth callback
            formElement.action = `${formElement.action}&${qs.stringify(
              {
                nickname,
                accept_terms: acceptTerms,
                accept_age_limit: acceptAgeLimit,
                accept_privacy_policy: acceptTerms,
                agreed_to_receive_offers_from_partners: acceptToShareAll,
                agreed_to_receive_offers_from_partners_specific:
                  acceptToShareSpecific,
                device_fingerprint: await getDeviceFingerPrint(),
                signup_sport: sport,
              },
              {
                format: 'RFC1738',
                arrayFormat: 'comma',
              }
            )}`;
            formElement.submit();
          }
        }
      }
    );
  }, [
    isAndroidApp,
    mode,
    nativeOAuthSignup,
    postMessage,
    registerOAuthHandler,
    getDeviceFingerPrint,
    track,
  ]);
  return (
    <>
      <OAuthForm provider="google_oauth2" ref={googleOAuth2Ref} />
      <OAuthForm provider="facebook" ref={facebookOauth2Ref} />
      <OAuthForm provider="apple" ref={appleOauth2Ref} />
    </>
  );
};

const StyledDialog = styled(Dialog)<{ $popMode: PopMode | null }>`
  ${({ $popMode }) =>
    $popMode
      ? css`
          visibility: hidden;
        `
      : null}
`;
const Root = styled(Vertical).attrs({ gap: 0 })`
  position: relative;
`;

const Wrapper = styled.div`
  &.signup {
    margin: auto;
    height: 100%;
    max-height: 100vh;

    ${Root} {
      height: 100%;
    }

    @media ${laptopAndAbove} {
      top: 50%;
      right: 0;
      left: 0;
      height: auto;
      position: absolute;
      transform: translateY(-50%);
    }
  }
`;

const Header = styled(Horizontal)`
  padding: var(--double-unit) 0;
  height: calc(40px + (4 * var(--unit)));
`;

const Frame = styled(WalletPlaceholder)`
  position: relative;
  top: 0;
  max-height: 100%;
  height: 486px;
  margin: 0;
  width: calc(100%);
  &.signup {
    height: 581px;
  }
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: var(--double-unit);
  right: var(--double-unit);
  z-index: 1;
`;

export const ConnectionDialog = ({
  open,
  mode,
  isMobileWebviewSignUp = false,
  onClose,
  setMode,
  popMode,
  defaultSport,
}: Props) => {
  const { signIn, setOnWalletResizeRequest, walletNode } = useWalletContext();
  const track = useEvents();
  const { registerHandler, sendRequest } = useMessagingContext();
  const [placeHolderStyle, setPlaceHolderStyle] = useState<
    { height: number } | undefined
  >();
  const [isSignUpAdditionalScreen, setIsSignUpAdditionalScreen] =
    useState<boolean>(false);
  const {
    flags: { lastTermsOfServiceUpdatedAt = '' },
  } = useFeatureFlags();
  const { up: isTablet } = useScreenSize('tablet');
  const { isMobileApp } = useIsMobileApp();

  const mustAcceptTermsFlag = mustAcceptTermsOfServiceFlag(
    lastTermsOfServiceUpdatedAt
  );

  useEffect(() => {
    if (mode === 'signup') {
      track('View Sign Up Modal', { variation: '' });
    }
  }, [mode, track]);

  useEffect(() => {
    if (mode === 'signin') {
      signIn();
    }
  }, [mode, signIn]);

  useEffect(
    () =>
      setOnWalletResizeRequest(dimensions => {
        if (isMobileWebviewSignUp || !walletNode || !dimensions) {
          return;
        }
        const currentDimensions = walletNode.getBoundingClientRect();
        if (dimensions.height !== currentDimensions.height) {
          setPlaceHolderStyle(prevState => {
            if (!prevState) {
              // Fix height resizing when switching signin/singup: use currentDimensions instead of dimensions. Cause dimensions have prev form value after switching.
              return { height: currentDimensions.height };
            }
            // if less height was actually requested, increment it
            if (!prevState.height || prevState.height !== dimensions.height) {
              return {
                ...prevState,
                height: dimensions.height,
              };
            }
            return prevState;
          });
        }
      }),
    [setOnWalletResizeRequest, walletNode, isMobileWebviewSignUp]
  );

  useEffect(
    () =>
      registerHandler<SetMode>('setMode', async ({ mode: inputMode }) => {
        setMode(inputMode);
        return {};
      }),
    [registerHandler, setMode]
  );

  useEffect(
    () =>
      registerHandler<SignUpAdditionalScreen>(
        'signUpAdditionalScreen',
        async () => {
          setIsSignUpAdditionalScreen(true);
          return {};
        }
      ),
    [registerHandler, setMode]
  );

  const backToSignUp = async () => {
    await sendRequest<BackFromSignUpAdditionalScreen>(
      'backFromSignUpAdditionalScreen',
      {}
    );
    setIsSignUpAdditionalScreen(false);
  };

  const onCloseFromAdditionalScreen = () => {
    backToSignUp();
    onClose();
  };

  const fullScreen = isMobileWebviewSignUp || !isTablet;
  const hideHeader = mode === 'signup' || isMobileWebviewSignUp;
  const hideCloseButton = isMobileApp && mode === 'signup';
  const dialogOnClose = isSignUpAdditionalScreen
    ? onCloseFromAdditionalScreen
    : onClose;
  const dialogOnBack = (isSignUpAdditionalScreen && backToSignUp) || undefined;

  return (
    <StyledDialog
      // Set a data-attribute we can look for on connection dialog
      data-dialog="ConnectionDialog"
      open={open}
      fullWidth
      maxWidth="xs"
      hideHeader
      onBack={dialogOnBack}
      onClose={dialogOnClose}
      fullScreen={mode === 'signup' || fullScreen}
      $popMode={popMode}
    >
      {({ CloseButton }) => (
        <Wrapper className={classNames(mode)}>
          <Root>
            {!hideHeader && (
              <>
                <Header center>
                  <LabelL as="span" bold>
                    <FormattedMessage {...glossary[mode]} />
                  </LabelL>
                </Header>
                {!hideCloseButton && (
                  <CloseButtonWrapper>
                    <CloseButton onClose={dialogOnClose} />
                  </CloseButtonWrapper>
                )}
              </>
            )}
            {!popMode && mode === 'signin' && (
              <Frame
                key={mode}
                className={classNames(
                  mode,
                  mustAcceptTermsFlag && {
                    lastTermsOfServiceUpdatedAt,
                  },
                  {
                    isMobileWebviewSignUp,
                  }
                )}
                withoutHeader={isMobileWebviewSignUp}
                style={placeHolderStyle}
              />
            )}
            {mode === 'signup' && (
              <ChooseYourSport
                onClose={dialogOnClose}
                defaultSport={defaultSport}
                isMobileWebviewSignUp={isMobileWebviewSignUp}
                WalletPortal={
                  <Frame
                    key={mode}
                    className={classNames(
                      mode,
                      mustAcceptTermsFlag && {
                        lastTermsOfServiceUpdatedAt,
                      },
                      {
                        isMobileWebviewSignUp,
                      }
                    )}
                    withoutHeader={isMobileWebviewSignUp}
                    style={
                      {
                        ...placeHolderStyle,
                        // Previously, used a default --header-size of 60.
                        // For the new signup flow we're increasing --header-size to accommodate better scrolling on small devices.
                        '--header-size': 140,
                      } as React.CSSProperties
                    }
                  />
                }
              />
            )}
            <ConnectionOAuthToolbarV2 mode={mode} />
          </Root>
        </Wrapper>
      )}
    </StyledDialog>
  );
};
