import { TypedDocumentNode, gql } from '@apollo/client';
import { ReactNode, useState } from 'react';
import { FormattedMessage, FormattedNumber, defineMessages } from 'react-intl';
import styled, { CSSProperties } from 'styled-components';

import { Link } from '@sorare/routing';

import { OrdinalRatingEnum } from '@sorare/core/src/__generated__/globalTypes';
import { fadeIn } from '@sorare/core/src/atoms/animations/keyframes';
import { Horizontal, Vertical } from '@sorare/core/src/atoms/layout/flex';
import { BodyS, LabelS } from '@sorare/core/src/atoms/typography';
import {
  PopoverArrow,
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
} from '@sorare/core/src/atoms/ui/Popover';
import { CornerBadge } from '@sorare/core/src/components/CornerBadge';
import { useIsLaptop } from '@sorare/core/src/hooks/device/useIsLaptop';
import { glossary } from '@sorare/core/src/lib/glossary';
import { Color } from '@sorare/core/src/style/types';
import { regularMaterial } from '@sorare/core/src/style/utils';

import { PredictionShirt } from './PredictionShirt';
import type { PlayerGameStatsPrediction_playingStatusOdds } from './__generated__/index.graphql';
import reliabilityHigh from './assets/reliability_high.svg';
import reliabilityLow from './assets/reliability_low.svg';
import reliabilityMedium from './assets/reliability_medium.svg';

const Chip = styled(Horizontal).attrs({ gap: 0.5 })`
  padding: 2px var(--unit);
  border-radius: 100px;
  background-color: var(--prediction-color);
  color: var(--c-black);
`;
const StyledLabelS = styled(LabelS).attrs({ brand: true })`
  font-variant-numeric: tabular-nums;
`;

const TooltipRoot = styled(Horizontal)`
  ${regularMaterial}
  border-radius: var(--unit);
  padding: var(--intermediate-unit) var(--double-unit);
`;
const StyledPopoverContent = styled(PopoverContent)`
  transform-origin: var(--radix-popover-content-transform-origin);
  animation: ${fadeIn} 0.2s ease-in;
`;
const StyledPopoverArrow = styled(PopoverArrow)`
  polygon {
    fill: var(--c-nd-150);
  }
`;
const StyledPredictionShirt = styled(PredictionShirt)`
  color: var(--prediction-color);
`;
const Overlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1;
  background-color: rgba(var(--c-rgb-black), 0.7);
  animation: ${fadeIn} 0.2s ease-in;
`;
const NoWrap = styled.span`
  white-space: nowrap;
`;

const messagesByReliability = defineMessages<OrdinalRatingEnum>({
  [OrdinalRatingEnum.LOW]: {
    id: 'composeTeam.PlayerGameStatsPrediction.reliabilityLow',
    defaultMessage: 'Reliability Low',
  },
  [OrdinalRatingEnum.MEDIUM]: {
    id: 'composeTeam.PlayerGameStatsPrediction.reliabilityMedium',
    defaultMessage: 'Reliability Medium',
  },
  [OrdinalRatingEnum.HIGH]: {
    id: 'composeTeam.PlayerGameStatsPrediction.reliabilityHigh',
    defaultMessage: 'Reliability High',
  },
});

const iconByReliability: Record<OrdinalRatingEnum, string> = {
  [OrdinalRatingEnum.LOW]: reliabilityLow,
  [OrdinalRatingEnum.MEDIUM]: reliabilityMedium,
  [OrdinalRatingEnum.HIGH]: reliabilityHigh,
};

const thresholds = [
  [49.5, 'low'],
  [79.5, 'medium'],
] as const;

const THRESHOLD_COLORS: { [key: string]: Color } = {
  low: 'var(--c-score-veryLow)',
  medium: 'var(--c-yellow-400)',
  high: 'var(--c-rivals-win)',
};

type Props = {
  playingStatusOdds: PlayerGameStatsPrediction_playingStatusOdds;
  variant: 'chip' | 'cornerBadge' | 'text';
  includesIcon?: boolean;
  newVersion?: boolean;
};

export const PlayerGameStatsPrediction = ({
  playingStatusOdds,
  variant,
  includesIcon,
  newVersion,
}: Props) => {
  const isLaptopAndAbove = useIsLaptop();
  const [open, setOpen] = useState(false);

  const { reliability, starterOddsBasisPoints } = playingStatusOdds;

  const threshold =
    thresholds.find(t => Number(t[0]) > starterOddsBasisPoints / 100)?.[1] ||
    'high';

  const color =
    reliability === OrdinalRatingEnum.LOW
      ? 'var(--c-nl-200-opaque)'
      : THRESHOLD_COLORS[threshold];

  let textContent = (
    <FormattedNumber value={starterOddsBasisPoints / 10000} style="percent" />
  );

  if (newVersion) {
    textContent = <NoWrap>{textContent}*</NoWrap>;
  }

  let content: ReactNode;
  if (variant === 'text') {
    content = (
      <Horizontal gap={0.5} style={{ color } as CSSProperties}>
        {includesIcon && <PredictionShirt size={1.5} />}
        <span>{textContent}</span>
      </Horizontal>
    );
  } else if (variant === 'chip') {
    content = (
      <Chip style={{ '--prediction-color': color } as CSSProperties}>
        <PredictionShirt size={1.5} />
        <StyledLabelS as="p">{textContent}</StyledLabelS>
      </Chip>
    );
  } else if (variant === 'cornerBadge') {
    content = (
      <CornerBadge color={color}>
        <PredictionShirt size={1.5} />
        <StyledLabelS as="p">{textContent}</StyledLabelS>
      </CornerBadge>
    );
  }

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const hoverProps = isLaptopAndAbove
    ? { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }
    : {};

  return (
    <>
      {open && !isLaptopAndAbove && <Overlay />}
      <PopoverRoot open={open} onOpenChange={setOpen}>
        <PopoverTrigger {...hoverProps}>{content}</PopoverTrigger>
        <PopoverPortal>
          <StyledPopoverContent collisionPadding={8} {...hoverProps}>
            <TooltipRoot gap={4}>
              <Horizontal
                style={{ '--prediction-color': color } as CSSProperties}
                gap={1.5}
              >
                <StyledPredictionShirt size={3} />
                <Vertical gap={0}>
                  <BodyS as="p">
                    <FormattedMessage
                      id="composeTeam.PlayerGameStatsPrediction.percentStarting"
                      defaultMessage="{percent, number, percent} Starting"
                      values={{ percent: starterOddsBasisPoints / 10000 }}
                    />
                  </BodyS>
                  <Horizontal gap={0.5}>
                    <img
                      src={iconByReliability[reliability]}
                      alt=""
                      width="10px"
                    />
                    <LabelS as="p" color="var(--c-nd-600)">
                      <FormattedMessage
                        {...messagesByReliability[reliability]}
                      />
                    </LabelS>
                  </Horizontal>
                </Vertical>
              </Horizontal>
              <Horizontal gap={0.5}>
                <LabelS as="p" color="var(--c-nd-400)">
                  <FormattedMessage {...glossary.poweredBy} />
                </LabelS>
                <Link to={playingStatusOdds.providerRedirectUrl}>
                  <img
                    src={playingStatusOdds.providerIconUrl}
                    alt=""
                    width="16"
                    height="16"
                  />
                </Link>
              </Horizontal>
            </TooltipRoot>
            <StyledPopoverArrow />
          </StyledPopoverContent>
        </PopoverPortal>
      </PopoverRoot>
    </>
  );
};

PlayerGameStatsPrediction.fragments = {
  playingStatusOdds: gql`
    fragment PlayerGameStatsPrediction_playingStatusOdds on PlayingStatusOdds {
      reliability
      starterOddsBasisPoints
      providerIconUrl
      providerRedirectUrl
    }
  ` as TypedDocumentNode<PlayerGameStatsPrediction_playingStatusOdds>,
};
