import { unitMapping } from 'lib/style';

type Props = {
  displayPlus?: boolean;
  size?: keyof typeof unitMapping;
};
export const AchievementBadgePlaceholder = ({
  displayPlus,
  size = 8,
}: Props) => {
  return (
    <svg
      width="64"
      height="64"
      style={{ width: unitMapping[size], height: unitMapping[size] }}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 6C41.7476 6 56 11.2158 56 11.2158C56 11.2158 56.0478 32.9461 50.6371 42.7741C45.7771 51.6017 32 58 32 58C32 58 18.2229 51.6017 13.3629 42.7741C7.95215 32.9461 8.00001 11.2158 8.00001 11.2158C8.00001 11.2158 22.2524 6 32 6Z"
        fill="url(#paint0_linear_2834_157587)"
      />
      {displayPlus && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 40C36.4183 40 40 36.4183 40 32C40 27.5817 36.4183 24 32 24C27.5817 24 24 27.5817 24 32C24 36.4183 27.5817 40 32 40ZM31 31V28H33V31H36V33H33V36H31V33H28V31H31Z"
          fill="#8A8A8A"
        />
      )}
      <defs>
        <linearGradient
          id="paint0_linear_2834_157587"
          x1="20.766"
          y1="17.8616"
          x2="37.309"
          y2="32.5804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4D4D4D" />
          <stop offset="1" stopColor="#323232" />
        </linearGradient>
      </defs>
    </svg>
  );
};
