import styled from 'styled-components';

import { Dropdown } from 'atoms/dropdowns/Dropdown';
import { FootballLogo } from 'atoms/icons/FootballLogo';
import { MLBLogo } from 'atoms/icons/MLBLogo';
import { NBALogo } from 'atoms/icons/NBALogo';
import { Vertical } from 'atoms/layout/flex';
import { HeadlineL } from 'atoms/typography';
import { FOOTBALL, MLB, NBA } from 'constants/__generated__/routes';

import { SportButtonContent } from './SportButtonContent';
import { SportLink } from './SportLink';

const DropdownContent = styled(Vertical)`
  padding: var(--double-unit);
  background-color: var(--c-black);
`;

type Props = {
  displaySelectedSportName?: boolean;
  withoutChevronIcon?: boolean;
};
export const SportSelector = ({
  displaySelectedSportName,
  withoutChevronIcon,
}: Props) => {
  return (
    <Dropdown
      forceDesktopBehavior
      label={({ onClick, open }) => (
        <button type="button" onClick={onClick}>
          <SportButtonContent
            displaySelectedSportName={displaySelectedSportName}
            withoutChevronIcon={withoutChevronIcon}
            active={open}
          />
        </button>
      )}
    >
      {({ closeDropdown }) => (
        <DropdownContent>
          <SportLink
            to={FOOTBALL}
            icon={<FootballLogo width={45} height={45} />}
            title={
              <HeadlineL brand bold>
                Football
              </HeadlineL>
            }
            onClick={closeDropdown}
          />
          <SportLink
            to={NBA}
            icon={<NBALogo width={45} height={45} />}
            title={
              <HeadlineL brand bold>
                NBA
              </HeadlineL>
            }
            onClick={closeDropdown}
          />
          <SportLink
            to={MLB}
            icon={<MLBLogo width={45} height={45} />}
            title={
              <HeadlineL brand bold>
                MLB
              </HeadlineL>
            }
            onClick={closeDropdown}
          />
        </DropdownContent>
      )}
    </Dropdown>
  );
};
