import { Vertical } from 'atoms/layout/flex';
import { LabelS } from 'atoms/typography';
import { copyright } from 'lib/copyright';

import { Socials } from './Socials';

export const Footer = () => {
  return (
    <Vertical>
      <Socials />
      <LabelS color="var(--c-nd-600)" className="text-center">
        {copyright}
      </LabelS>
    </Vertical>
  );
};
