import classNames from 'classnames';
import { useState } from 'react';
import styled from 'styled-components';

import { FadeInOut } from 'atoms/animations/FadeInOut';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import { LabelXS } from 'atoms/typography';
import { OnboardingHighlightWrapper } from 'components/onboarding/OnboardingHighlightWrapper';
import { useSportContext } from 'contexts/sport';
import { useIsLaptop } from 'hooks/device/useIsLaptop';
import { laptopAndAbove } from 'style/mediaQuery';

import { MarketCredits } from './MarketCredits';
import { MobileUserCurrenciesDialog } from './MobileUserCurrenciesDialog';
import { MoneyBalances } from './MoneyBalances';
import {
  CurrencyData,
  InGameCurrencyAgreggated,
  useUserCurrencies,
} from './useUserCurrencies';

const Root = styled(Vertical)`
  position: relative;
  width: 100%;
`;
const InGameCurrenciesWrapper = styled(Horizontal).attrs({ gap: 0.5 })`
  & > :not(:first-child) {
    margin-left: calc(-1 * var(--double-unit));
  }

  @media ${laptopAndAbove} {
    .sidebarCollapsed & {
      flex-direction: column;
      & > * {
        width: 100%;
      }
    }

    & > :not(:first-child) {
      margin-left: 0;
    }
  }
`;
const InGameCurrencyWrapper = styled(Horizontal).attrs({ gap: 0.5 })`
  background-color: var(--c-black);
  width: var(--quadruple-unit);
  height: var(--quadruple-unit);
  border-radius: 50%;
  border: 1px solid var(--c-nd-150);
  padding: 0 var(--unit);

  &.expanded {
    border-radius: var(--double-unit);
    width: auto;
  }

  @media ${laptopAndAbove} {
    flex-direction: column;
    justify-content: center;

    flex: 1;
    padding: var(--unit);
    border-radius: var(--unit);
    height: auto;
  }
`;

type Props = {
  expandedCurrencies?: InGameCurrencyAgreggated[];
  sidebarCollapsed?: boolean;
};
export const UserCurrencies = ({
  expandedCurrencies,
  sidebarCollapsed,
}: Props) => {
  const isLaptopAndAbove = useIsLaptop();
  const { sport } = useSportContext();
  const [mobileDialogOpened, setMobileDialogOpened] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyData | null>(
    null
  );

  const currencies = useUserCurrencies(expandedCurrencies);

  return (
    <>
      <MobileUserCurrenciesDialog
        open={mobileDialogOpened}
        onClose={() => setMobileDialogOpened(false)}
        currencies={currencies}
      />
      <Root
        as={isLaptopAndAbove ? 'div' : 'button'}
        onClick={
          isLaptopAndAbove ? undefined : () => setMobileDialogOpened(true)
        }
      >
        <Vertical onMouseLeave={() => setSelectedCurrency(null)}>
          {isLaptopAndAbove && !sidebarCollapsed && (
            <FadeInOut>{selectedCurrency?.details?.({})}</FadeInOut>
          )}
          <InGameCurrenciesWrapper>
            {currencies.map((currency, i) => {
              const {
                id,
                icon,
                expanded,
                visibleOnSports,
                amount,
                onClick,
                onboardingHighlightName,
              } = currency;

              return (
                visibleOnSports.includes(sport) && (
                  <OnboardingHighlightWrapper
                    highlightName={
                      onboardingHighlightName || 'CommonEssenceBalance'
                    }
                    wrap={!!onboardingHighlightName}
                    key={id}
                  >
                    <InGameCurrencyWrapper
                      as={isLaptopAndAbove ? 'button' : 'div'}
                      onClick={isLaptopAndAbove ? onClick : undefined}
                      className={classNames({
                        expanded: !isLaptopAndAbove && expanded,
                      })}
                      style={{ zIndex: currencies.length - i }}
                      onMouseEnter={() => setSelectedCurrency(currency)}
                    >
                      {icon}
                      {(isLaptopAndAbove || expanded) && (
                        <LabelXS bold>{amount}</LabelXS>
                      )}
                    </InGameCurrencyWrapper>
                  </OnboardingHighlightWrapper>
                )
              );
            })}
          </InGameCurrenciesWrapper>
        </Vertical>
        {isLaptopAndAbove && (
          <>
            <MoneyBalances sidebarCollapsed={sidebarCollapsed} />
            <MarketCredits sport={sport} sidebarCollapsed={sidebarCollapsed} />
          </>
        )}
      </Root>
    </>
  );
};
