import classNames from 'classnames';
import { ComponentProps, ReactNode } from 'react';
import { To, matchPath, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { Link, NavLink, catchAll } from '@sorare/routing';

import { Horizontal, Vertical } from 'atoms/layout/flex';
import { Tooltip } from 'atoms/tooltip/Tooltip';
import { LabelM } from 'atoms/typography';
import { Badge } from 'atoms/ui/Badge';
import { LiveDot } from 'components/live/LiveDot';

const hideNavItemLabel = keyframes`
  from {
    /* this is the width of the sidebar non-collapsed */
    max-width: 240px;
  }
  to {
    max-width: calc(5 * var(--unit));
    overflow: hidden;
  }
`;

const Root = styled(Horizontal)`
  justify-content: center;
  width: 100%;
  cursor: pointer;
  color: var(--c-nd-600);

  &.horizontal {
    justify-content: flex-start;
    padding: var(--intermediate-unit);
    border-radius: var(--unit);

    &&.active {
      background-color: var(--c-nd-50);
    }
    &:hover {
      background-color: rgba(var(--c-rgb-white), 0.04);
      color: var(--c-white);
    }
    &.sidebarCollapsed {
      animation: 300ms ${hideNavItemLabel} ease-in-out forwards;
    }
    &:not(.active).sidebarCollapsed:hover {
      transform: scale(1.2);
    }
    transition: 350ms transform cubic-bezier(0.02, 0.49, 0.51, 0.99);
  }
  &.active {
    color: var(--c-white);
  }
`;
const Layout = styled(Vertical)`
  position: relative;
  align-items: center;
  gap: var(--half-unit);
  justify-content: flex-start;

  .horizontal & {
    flex-direction: row;
    gap: var(--intermediate-unit);
  }
  ${Root}:not(.active, .sidebarCollapsed):hover > & {
    transform: translateX(var(--half-unit));
  }
  transition: 350ms transform cubic-bezier(0.02, 0.49, 0.51, 0.99);
`;
const RelativeWrapper = styled(Horizontal)`
  position: relative;
`;
const IconWrapper = styled(Horizontal).attrs({ center: true })`
  min-width: var(--double-unit);
  min-height: var(--double-unit);

  .active.horizontal & {
    transform: scale(1.2);
  }
`;
const FullWidthSBHorizontal = styled(Horizontal)`
  width: 100%;
  justify-content: center;

  .horizontal & {
    justify-content: space-between;
    white-space: nowrap;
  }
`;
const PlayLiveDotWrapper = styled.div`
  position: absolute;
  top: calc(-1 * var(--unit));
  right: calc(-1 * var(--double-unit));
`;
const StyledBadge = styled(Badge)`
  .horizontal & {
    margin-right: var(--unit);
  }
`;
const NavLabel = styled.span`
  font: var(--t-label-s);
  .horizontal & {
    font: var(--t-label-l);
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid var(--c-nd-150);
`;

type Props = {
  to?: To;
  icon?: ({ isActive }: { isActive: boolean }) => ReactNode;
  label: string;
  active?: boolean;
  showLiveDot?: boolean;
  badge?: { content: ReactNode; ariaLabel?: string };
  useClassicLink?: boolean;
  variant?: 'horizontal' | 'vertical';
  sidebarCollapsed?: boolean;
  onClick?: () => void;
  keepSearchParams?: boolean;
  matches?: ComponentProps<typeof NavLink>['matches'];
};
export const NewNavItem = ({
  to,
  icon,
  label,
  active,
  showLiveDot,
  badge,
  useClassicLink,
  variant = 'horizontal',
  sidebarCollapsed,
  ...rest
}: Props) => {
  const location = useLocation();
  const LinkComponent = useClassicLink ? Link : NavLink;

  const isActive = !!(
    to && !!matchPath(catchAll(to.toString()), location.pathname)
  );

  return (
    <Root
      as={to ? LinkComponent : 'div'}
      to={to}
      state={{
        fromNavItem: true,
      }}
      className={classNames({
        horizontal: variant === 'horizontal',
        active,
        sidebarCollapsed,
      })}
      {...rest}
    >
      <Layout>
        <Tooltip
          skip={!sidebarCollapsed || variant === 'vertical'}
          title={<LabelM>{label}</LabelM>}
          placement="right"
        >
          <IconWrapper>{icon?.({ isActive })}</IconWrapper>
        </Tooltip>
        <FullWidthSBHorizontal>
          <RelativeWrapper>
            <NavLabel>{label}</NavLabel>
            {showLiveDot && (
              <PlayLiveDotWrapper>
                <LiveDot animate size="md" />
              </PlayLiveDotWrapper>
            )}
          </RelativeWrapper>
          {badge && (
            <StyledBadge
              badgeContent={badge.content}
              aria-label={badge.ariaLabel}
            />
          )}
        </FullWidthSBHorizontal>
      </Layout>
    </Root>
  );
};
