import { TypedDocumentNode, gql } from '@apollo/client';
import {
  faCircleExclamation,
  faRotate,
} from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { CartItemState } from '@sorare/core/src/__generated__/globalTypes';
import { LoadingButton } from '@sorare/core/src/atoms/buttons/LoadingButton';
import { FontAwesomeIcon } from '@sorare/core/src/atoms/icons';
import { Horizontal } from '@sorare/core/src/atoms/layout/flex';
import { LabelM } from '@sorare/core/src/atoms/typography';
import { glossary } from '@sorare/core/src/lib/glossary';
import { Color } from '@sorare/core/src/style/types';

import { useCart } from 'hooks/useCart';

import type { CartItemStateWarning_cartItem } from './__generated__/index.graphql';

const Warning = styled(Horizontal).attrs({ gap: 2 })`
  padding: var(--intermediate-unit) var(--double-unit);
  border-radius: var(--unit);
  justify-content: space-between;
`;

const states: Record<
  CartItemState,
  | {
      color: Color;
      backgroundColor: Color;
      icon: ReactNode;
      title: ReactNode;
      buttonCta: ReactNode;
    }
  | undefined
> = {
  [CartItemState.REPLACED]: {
    color: 'var(--c-yellow-400)',
    backgroundColor: 'var(--c-surface-caution)',
    icon: <FontAwesomeIcon icon={faRotate} />,
    title: (
      <FormattedMessage
        id="cartItems.replaced.warning"
        defaultMessage="Card and price updated"
      />
    ),
    buttonCta: <FormattedMessage {...glossary.gotIt} />,
  },
  [CartItemState.REMOVED]: {
    color: 'var(--c-red-600)',
    backgroundColor: 'var(--c-surface-error)',
    icon: <FontAwesomeIcon icon={faCircleExclamation} />,
    title: (
      <FormattedMessage
        id="cartItems.removed.warning"
        defaultMessage="No other instant buy available"
      />
    ),
    buttonCta: <FormattedMessage {...glossary.remove} />,
  },
  [CartItemState.CURRENT]: undefined,
};

type Props = {
  cartItem: CartItemStateWarning_cartItem;
  trackRemove: () => void;
};
export const CartItemStateWarning = ({ cartItem, trackRemove }: Props) => {
  const { removeFromCart, refreshCart } = useCart();
  const { state } = cartItem || {};

  if (!state) return null;
  const attrs = states[state];

  const onClick = async () => {
    switch (state) {
      case CartItemState.REMOVED: {
        trackRemove?.();
        return removeFromCart.mutate([cartItem.id]);
      }
      case CartItemState.REPLACED:
        return refreshCart.mutate([cartItem.id]);
      default:
        return null;
    }
  };
  const loading = (() => {
    switch (state) {
      case CartItemState.REMOVED:
        return removeFromCart.loading;
      case CartItemState.REPLACED:
        return refreshCart.loading;
      default:
        return false;
    }
  })();

  if (!attrs) return null;
  return (
    <Warning style={{ backgroundColor: attrs.backgroundColor }}>
      <LabelM bold color={attrs.color}>
        {attrs.icon} {attrs.title}
      </LabelM>
      <LoadingButton
        loading={loading}
        size="small"
        color="secondary"
        onClick={onClick}
      >
        {attrs.buttonCta}
      </LoadingButton>
    </Warning>
  );
};

CartItemStateWarning.fragments = {
  cartItem: gql`
    fragment CartItemStateWarning_cartItem on CartItem {
      id
      state
    }
  ` as TypedDocumentNode<CartItemStateWarning_cartItem>,
};
