import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';

import { IconButton } from '@sorare/core/src/atoms/buttons/IconButton';
import { SearchBarDialog } from '@sorare/core/src/components/navigation/NewAppNavigation/SearchBar/SearchBarDialog';

export const SearchBarHeaderButton = () => {
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  return (
    <>
      <IconButton
        icon={faSearch}
        color="transparent"
        onClick={() => setSearchBarOpen(true)}
      />
      {searchBarOpen && (
        <SearchBarDialog onClose={() => setSearchBarOpen(false)} />
      )}
    </>
  );
};
