import {
  faDiscord,
  faFacebook,
  faInstagram,
  faReddit,
  faTiktok,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import styled, { CSSProperties } from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import {
  DISCORD_INVITATION_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  REDDIT_URL,
  TIKTOK_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from 'constants/externalLinks';

const SOCIALS = [
  {
    icon: <FontAwesomeIcon icon={faXTwitter} />,
    hoverColor: 'var(--c-nd-150)',
    href: TWITTER_URL,
  },
  {
    icon: <FontAwesomeIcon icon={faFacebook} />,
    hoverColor: 'var(--c-social-facebook)',
    href: FACEBOOK_URL,
  },
  {
    icon: <FontAwesomeIcon icon={faInstagram} />,
    hoverColor: 'var(--c-social-instagram)',
    href: INSTAGRAM_URL,
  },
  {
    icon: <FontAwesomeIcon icon={faDiscord} />,
    hoverColor: 'var(--c-social-discord)',
    href: DISCORD_INVITATION_URL,
  },
  {
    icon: <FontAwesomeIcon icon={faReddit} />,
    hoverColor: 'var(--c-social-reddit)',
    href: REDDIT_URL,
  },
  {
    icon: <FontAwesomeIcon icon={faYoutube} />,
    hoverColor: 'var(--c-social-youtube)',
    href: YOUTUBE_URL,
  },
  {
    icon: <FontAwesomeIcon icon={faTiktok} />,
    hoverColor: 'var(--c-social-tiktok)',
    href: TIKTOK_URL,
  },
];

const Root = styled(Horizontal).attrs({ center: true })`
  width: 100%;
  padding: var(--unit);

  & > * {
    flex: 1;
  }
`;

const ExternalLink = styled.a`
  color: var(--c-nd-600);
  &:hover {
    color: var(--hover-color);
  }
  transition: 300ms color ease-in-out;
`;

export const Socials = () => {
  return (
    <Root>
      {SOCIALS.map(({ icon, href, hoverColor }) => (
        <ExternalLink
          key={href}
          href={href}
          target="_blank"
          rel="noreferrer"
          style={{ '--hover-color': hoverColor } as CSSProperties}
          className="text-center"
        >
          {icon}
        </ExternalLink>
      ))}
    </Root>
  );
};
