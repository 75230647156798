import { TypedDocumentNode } from '@apollo/client';
import gqlMacro from 'graphql-tag.macro';

import { Sport } from '__generated__/globalTypes';
import { useQuery } from 'hooks/graphql/useQuery';
import { randomizedPollInterval } from 'lib/gql';

import {
  BaseballNavQuery,
  BaseballNavQueryVariables,
  FootballNavQuery,
  FootballNavQueryVariables,
  NBANavQuery,
  NBANavQueryVariables,
} from './__generated__/useNavData.graphql';

const currentUserFragment = gqlMacro`
  fragment useNavData_currentUser on CurrentUser {
    slug
    unclaimedSo5Rewards(sport: $sport) {
      id
      slug
      coinAmount
      so5Fixture {
        slug
        endDate
      }
    }
    unopenedProbabilisticBundles(sport: $sport) {
      totalCount
    }
    managerProgressionTasksCount(
      sport: $sport
      type: MANAGER_PROGRESSION_CHALLENGES
      state: COMPLETED
    )
  }
`;

const NAV_ITEMS_QUERY_FOOTBALL = gqlMacro`
  query FootballNavQuery($sport: Sport = FOOTBALL) {
    so5 {
      myAvailableFixtureStory {
        id
        aasmState
      }
    }
    currentUser {
      slug
      ...useNavData_currentUser
    }
    football {
      currentManagerHasLiveGames
      rivals {
        id
        currentManager {
          id
          unreadStories {
            id
          }
        }
      }
    }
  }
  ${currentUserFragment}
` as TypedDocumentNode<FootballNavQuery, FootballNavQueryVariables>;

const NAV_ITEMS_QUERY_BASEBALL = gqlMacro`
  query BaseballNavQuery($sport: Sport = BASEBALL) {
    currentUser {
      slug
      ...useNavData_currentUser
    }
    so5 {
      so5Fixture(sport: BASEBALL, type: LIVE) {
        slug
        mySo5LineupsCount
      }
    }
  }
  ${currentUserFragment}
` as TypedDocumentNode<BaseballNavQuery, BaseballNavQueryVariables>;

const NAV_ITEMS_QUERY_NBA = gqlMacro`
  query NBANavQuery($sport: Sport = NBA) {
    currentUser {
      slug
      ...useNavData_currentUser
    }
    so5 {
      so5Fixture(sport: NBA, type: LIVE) {
        slug
        mySo5LineupsCount
      }
    }
  }
  ${currentUserFragment}
` as TypedDocumentNode<NBANavQuery, NBANavQueryVariables>;

const POLL_INTERVAL = randomizedPollInterval(5);

export const useNavData = (sport: Sport) => {
  const { data: football } = useQuery(NAV_ITEMS_QUERY_FOOTBALL, {
    pollInterval: POLL_INTERVAL,
    skip: sport !== Sport.FOOTBALL,
  });
  const { data: mlb } = useQuery(NAV_ITEMS_QUERY_BASEBALL, {
    pollInterval: POLL_INTERVAL,
    skip: sport !== Sport.BASEBALL,
  });
  const { data: nba } = useQuery(NAV_ITEMS_QUERY_NBA, {
    pollInterval: POLL_INTERVAL,
    skip: sport !== Sport.NBA,
  });

  return {
    football,
    mlb,
    nba,
  };
};
