import {
  faFile,
  faGear,
  faInfoCircle,
  faList,
  faQuestionCircle,
  faQuoteLeft,
} from '@fortawesome/pro-solid-svg-icons';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal, Vertical } from 'atoms/layout/flex';
import {
  BLOG,
  GAME_RULES,
  HELP,
  LEGAL_NOTICE,
  SETTINGS,
  TERMS_AND_CONDITIONS,
} from 'constants/__generated__/routes';
import { navLabels } from 'lib/glossary';

import { NewNavItem, Separator } from '../../../NewNavItem';
import { Footer } from './Footer';

const Root = styled(Vertical).attrs({ gap: 0.5 })`
  border-radius: var(--intermediate-unit);
  background-color: var(--c-black);
`;
const SeparatorWrapper = styled(Horizontal)`
  width: 100%;
  padding: var(--unit) var(--unit) var(--half-unit) var(--unit);
`;

type Props = { onClick?: () => void; noFooter?: boolean };
export const MoreNavItems = ({ onClick, noFooter }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Root onClick={onClick}>
      <NewNavItem
        icon={() => <FontAwesomeIcon icon={faGear} />}
        label={formatMessage(navLabels.settings)}
        to={SETTINGS}
      />
      <NewNavItem
        icon={() => <FontAwesomeIcon icon={faQuoteLeft} />}
        label={formatMessage(navLabels.blog)}
        to={BLOG}
      />
      <NewNavItem
        icon={() => <FontAwesomeIcon icon={faQuestionCircle} />}
        label={formatMessage(navLabels.help)}
        to={HELP}
      />
      <NewNavItem
        icon={() => <FontAwesomeIcon icon={faInfoCircle} />}
        label={formatMessage(navLabels.gameRules)}
        to={GAME_RULES}
      />
      <NewNavItem
        icon={() => <FontAwesomeIcon icon={faList} />}
        label={formatMessage(navLabels.terms)}
        to={TERMS_AND_CONDITIONS}
      />
      <NewNavItem
        icon={() => <FontAwesomeIcon icon={faFile} />}
        label={formatMessage(navLabels.legalNotice)}
        to={LEGAL_NOTICE}
      />
      {!noFooter && (
        <>
          <SeparatorWrapper>
            <Separator />
          </SeparatorWrapper>
          <Footer />
        </>
      )}
    </Root>
  );
};
