import styled from 'styled-components';

import { Dialog } from 'components/dialog';
import { MultiSportSearchBar } from 'components/search/MultiSportSearchBar';

const StyledDialog = styled(Dialog)`
  top: 0;
  background: var(--c-black);
  border: 1px solid var(--c-nd-150);
  border-radius: var(--triple-unit);
  padding: var(--unit);
  margin: 0 auto auto auto;
`;

type Props = { onClose?: () => void };
export const SearchBarDialog = ({ onClose }: Props) => {
  return (
    <StyledDialog
      open
      onClose={onClose}
      fullScreen={false}
      maxWidth="xs"
      fullWidth
      hideHeader
    >
      <div className="forceSearchbarForDesktop">
        <MultiSportSearchBar onExit={false} onSelect={() => onClose?.()} />
      </div>
    </StyledDialog>
  );
};
