import { faEuro, faGbp, faUsd } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from 'atoms/icons';
import { Horizontal } from 'atoms/layout/flex';
import { LabelM } from 'atoms/typography';
import { Dots } from 'atoms/ui/Dots';
import { useCurrentUserContext } from 'contexts/currentUser';
import { useFiatBalance } from 'hooks/wallets/useFiatBalance';

type Props = { collapsed?: boolean };
export const FiatBalance = ({ collapsed }: Props) => {
  const { availableBalanceWithCurrencySymbol } = useFiatBalance({
    notation: 'compact',
    compactDisplay: 'short',
  });
  const { currentUser, fiatCurrency } = useCurrentUserContext();

  const currencyIcon = {
    EUR: faEuro,
    GBP: faGbp,
    USD: faUsd,
  }[fiatCurrency.code];

  const hideBalance = currentUser?.userSettings?.hideBalance;

  if (collapsed) {
    return (
      <Horizontal center>
        <FontAwesomeIcon icon={currencyIcon} size="1x" />
      </Horizontal>
    );
  }

  return hideBalance ? (
    <Horizontal center>
      <FontAwesomeIcon icon={faEuro} />
      <Horizontal gap={0.5}>
        <Dots count={3} size="small" />
      </Horizontal>
    </Horizontal>
  ) : (
    <LabelM bold>{availableBalanceWithCurrencySymbol}</LabelM>
  );
};
