import { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { Horizontal } from 'atoms/layout/flex';
import { Color, HexColor } from 'style/types';

const Root = styled.div`
  --triangle-size: var(--half-unit);
  margin-right: calc(-1 * var(--triangle-size));
  width: min-content;
  position: relative;
  cursor: pointer;
`;

const Content = styled(Horizontal).attrs({ gap: 0.5 })`
  background-color: var(--color);
  padding: 2px 6px 2px var(--unit);
  border-radius: 100px 40px 0px 100px;
  color: var(--c-black);
`;

const Triangle = styled.div`
  width: var(--triangle-size);
  height: var(--triangle-size);
  position: absolute;
  right: 0;
  clip-path: polygon(100% 0, 0 0, 0 100%);
  background: var(--color);

  &:after {
    content: '';
    position: absolute;
    inset: 0;

    background-color: rgba(var(--c-rgb-black), 0.6);
  }
`;

type Props = {
  color: Color | HexColor;
  children: ReactNode;
};

export const CornerBadge = ({ color, children }: Props) => {
  return (
    <Root style={{ '--color': color } as CSSProperties}>
      <Content gap={0.5}>{children}</Content>
      <Triangle />
    </Root>
  );
};
