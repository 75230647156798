import classNames from 'classnames';
import { CSSProperties, ComponentProps, ReactNode } from 'react';
import styled from 'styled-components';

import { HexagonShape } from 'atoms/icons/HexagonShape';
import { Horizontal } from 'atoms/layout/flex';
import { absCenter, unitMapping } from 'lib/style';
import { Color } from 'style/types';

const Wrapper = styled(Horizontal).attrs({ gap: 0, center: true })`
  position: relative;
  width: 32px;
  height: 32px;
  color: white;
  isolation: isolate;
  &.disabled {
    color: var(--c-black);
  }
  &.invalid {
    color: var(--c-red-600);
  }
`;
const Hexagon = styled(HexagonShape)`
  .disabled & path {
    fill: #ededed;
  }
  .invalid & path {
    fill: #5d3328;
    stroke: #5d3328;
  }
`;
const Score = styled(Horizontal).attrs({ gap: 0, center: true })`
  ${absCenter}
  font-weight: var(--t-bold);
  font-size: var(--fs-14);
  font-family: var(--brand-font);
  font-style: normal;
  letter-spacing: -0.03em;
  z-index: 1;

  &.inverted {
    color: var(--color);
  }
  &.small {
    font-size: 12px;
  }
`;

type Props = {
  score: ReactNode;
  disabled?: boolean;
  invalid?: boolean;
  title?: string;
  className?: string;
  style?: CSSProperties;
  color?: Color;
} & Pick<ComponentProps<typeof HexagonShape>, 'size' | 'inverted'>;
export const Cost = ({
  score,
  disabled = false,
  invalid = false,
  title,
  className,
  style,
  color,
  size,
  inverted,
}: Props) => {
  const roundedScore = typeof score === 'number' ? Math.round(score) : score;

  return (
    <Wrapper
      aria-label="cost"
      className={classNames(className, { disabled, invalid })}
      style={{
        ...style,
        ...(size
          ? { width: unitMapping[size], height: unitMapping[size] }
          : {}),
      }}
      title={title}
    >
      <Score
        style={invalid ? undefined : ({ '--color': color } as CSSProperties)}
        className={classNames({ inverted, small: size && size <= 3 })}
      >
        {roundedScore}
      </Score>
      <Hexagon fill={color} size={size} inverted={inverted} />
    </Wrapper>
  );
};
