import { ReactNode, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { catchAll } from '@sorare/routing';

import { Horizontal, SBHorizontal } from '@sorare/core/src/atoms/layout/flex';
import { ANY_SPORT_MARKET } from '@sorare/core/src/constants/__generated__/routes';
import { useIsMobileApp } from '@sorare/core/src/hooks/useIsMobileApp';
import { lazy } from '@sorare/core/src/lib/retry';
import { matchAnySportPath } from '@sorare/core/src/lib/routing/generateSportPath';

import { SearchBarHeaderButton } from '../SearchBarHeaderButton';
import { WalletButton } from '../WalletButton';

const Notifications = lazy(
  async () => import('components/notification/Notifications'),
  'Notifications'
);

const NavigationCart = lazy(
  async () => import('@sorare/marketplace/src/components/cart/NavigationCart'),
  'NavigationCart'
);

const Root = styled(SBHorizontal)`
  position: relative;
  border-bottom: 1px solid var(--c-nd-150);
  padding: var(--intermediate-unit) var(--double-unit);
`;
const PageHeaderRightButton = styled(Horizontal).attrs({ gap: 0.5 })`
  margin-left: auto;
`;
const OverflowHiddenWrapper = styled.div`
  overflow: hidden;
`;
const PageHeaderTitle = styled(OverflowHiddenWrapper)`
  width: 100%;
`;

type Props = { extraHeaderButton?: ReactNode };
export const MobileWebAppHeader = ({ extraHeaderButton }: Props) => {
  const { isAndroidApp } = useIsMobileApp();
  const { pathname } = useLocation();

  const isMarket = !!matchAnySportPath(catchAll(ANY_SPORT_MARKET), pathname);

  return (
    <Root>
      <Horizontal>
        <PageHeaderTitle id="page-header-title" />
        <OverflowHiddenWrapper id="page-header-title-breadcrumb" />
        <PageHeaderRightButton id="page-header-right-button" />
      </Horizontal>
      <Horizontal gap={0.5}>
        {extraHeaderButton}
        {isMarket && <WalletButton />}
        <Suspense fallback={null}>
          {!isAndroidApp && <NavigationCart />}
        </Suspense>
        <SearchBarHeaderButton />
        <Suspense fallback={null}>
          <Notifications />
        </Suspense>
      </Horizontal>
    </Root>
  );
};
