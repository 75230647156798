import {
  CSSProperties,
  ReactNode,
  RefObject,
  createContext,
  useContext,
} from 'react';

export type OnboardingHighlightName =
  | 'RivalsDraftCard'
  | 'RivalsDraftCost'
  | 'RivalsDraftForm'
  | 'RivalsDraftBudget'
  | 'RivalsTactics'
  | 'RivalsTacticBar'
  | 'ArenaPlayButton'
  | 'ClaimFirstBoxMissionDesktop'
  | 'ClaimFirstBoxMissionMobile'
  | 'RankDiamond'
  | 'DivDiamond'
  | 'RepDiamond'
  | 'PlayBanners'
  | 'NBAHomeDailyBlock'
  | 'ComposeTeamAverageScore'
  | 'ManagerProfileGallery'
  | 'ManagerProfileGalleryCard'
  | 'MyCardsCommonMarket'
  | 'BuyCommonCard'
  | 'CommonMarketHeader'
  | 'FixtureSelectorContainer'
  | 'PlayProEmptyState'
  | 'DailyClaimButton'
  | 'ComposeTeamRemainingPoints'
  | 'ComposeTeamBenchCardFooter'
  | 'CollectionPreviewCard'
  | 'CollectionPreviewPicture'
  | 'CollectionPreviewName'
  | 'CollectionPreviewCollectionBonusLabel'
  | 'CollectionPreviewCollectionBonusProgressBar'
  | 'CollectionPreviewStatsCards'
  | 'CollectionPreviewStatsRank'
  | 'CollectionPreviewStatsScore'
  | 'CollectionNavigationSwitch'
  | 'PlayPro'
  | 'CardPackGroup'
  | 'DropCardCta'
  | 'CommonEssenceBalance'
  | 'MarketInfoIcon'
  | 'MLBHomePlayBlock'
  | 'AsideEnergyBalance'
  | 'DailyMissions'
  | 'WeeklyMissions'
  | 'StreaksMissions'
  | 'Missions';

export type OnboardingHighlightConfig = {
  circle?: boolean;
  padding?: number;
  borderRadius?: CSSProperties['borderRadius'];
  border?: boolean;
  filter?: CSSProperties['filter'];
  onClick?: () => void;
  scrollIntoView?: boolean;
};
type MeasureHighlightCallback = () => DOMRect | null;

export type Highlight = {
  name: OnboardingHighlightName;
  measureCallback: MeasureHighlightCallback;
  config?: OnboardingHighlightConfig;
  children: ReactNode;
  refToScroll?: RefObject<HTMLElement>;
};
type OnboardingHighlightContext = {
  registerHighlight: ({
    name,
    measureCallback,
    config,
    children,
  }: Highlight) => void;
  highlights: Highlight[];
  activeHighlights: OnboardingHighlightName[];
  setActiveHighlights: (highlights: OnboardingHighlightName[]) => void;
};

export const onboardigHighlightContext =
  createContext<OnboardingHighlightContext | null>(null);

export const useOnboardingHighLightContext = () =>
  useContext(onboardigHighlightContext)!;
