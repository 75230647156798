import { TypedDocumentNode, gql } from '@apollo/client';

import { unitMapping } from '@sorare/core/src/lib/style';

import { LeagueDot_so5LeaderboardGroupInterface } from './__generated__/index.graphql';

type Props = {
  so5LeaderboardGroupInterface: LeagueDot_so5LeaderboardGroupInterface;
  size?: keyof typeof unitMapping;
};

export const LeagueDot = ({
  so5LeaderboardGroupInterface,
  size = 0.5,
}: Props) => {
  return (
    <svg
      viewBox="0 0 2 2"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: unitMapping[size] }}
    >
      <circle cx="1" cy="1" r="1" fill={so5LeaderboardGroupInterface.color} />
    </svg>
  );
};

LeagueDot.fragments = {
  so5LeaderboardGroupInterface: gql`
    fragment LeagueDot_so5LeaderboardGroupInterface on So5LeaderboardGroupInterface {
      slug
      color(variant: PRIMARY)
    }
  ` as TypedDocumentNode<LeagueDot_so5LeaderboardGroupInterface>,
};
